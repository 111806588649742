<template>
  <div>
    <div class="container-fluid">
      <div class="row clearfix">
        <div class="column full">
          <!-- customize -->
          <!--
          <picture v-if="blog.thumbnail">
            <source
              type="image/webp"
              :srcset="
                blog.thumbnail + $store.state.publicLang.general.image_webp
              "
            />
            <img
              :src="blog.thumbnail"
              :alt="'「' + blog.title + '」' + $store.state.publicLang.news.alt"
              class="w-100 m-0"
            />
          </picture>
          -->
          <h3 class="iv2-blog-detail__title">{{ blog.title }}</h3>
          <div class="iv2-blog-detail__subtitle">
            <span v-if="blog.opened_at" class="iv2-blog-detail__date">
              {{ blog.opened_at }}
            </span>
            <span v-if="blog.category_name" class="iv2-news__badge">
              {{ blog.category_name }}
            </span>
          </div>
          <div class="d-lg-flex justify-content-end">
            <div
              v-if="useShare == 1"
              class="d-md-flex align-items-center mr-md-auto mb-md-4 mb-lg-0"
            >
              <span class="d-block d-md-inline-block mr-4">{{
                $store.state.publicLang.blog.sns_share
              }}</span>
              <TwitterShare :href="url" class="mr-2 mr-md-3 mb-3 mb-md-0" />
              <FacebookShare :href="url" class="mr-2 mr-md-3 mb-3 mb-md-0" />
              <LineShare :href="url" class="mr-2 mr-md-3 mb-3 mb-md-0" />
              <PinterestShare :href="url" class="mr-2 mr-md-3 mb-3 mb-md-0" />
              <MailShare :href="url" class="mr-2 mr-md-3 mb-3 mb-md-0" />
              <UrlShare :href="url" class="mr-2 mr-md-3 mb-3 mb-md-0" />
            </div>

            <!-- いいねボタン -->
            <Favorite :items="blog" @update="updateDetail()" />
          </div>
          <hr />
        </div>
      </div>
    </div>

    <div class="container-fluid" v-html="blog.content"></div>

    <div class="container-fluid">
      <div class="row clearfix">
        <div v-if="useShare == 1" class="column full">
          <div class="spacer height-80"></div>
          <div class="row">
            <div class="col-md-6 col-lg-4 mb-4">
              <TwitterShare :type="'box'" :href="url" />
            </div>
            <div class="col-md-6 col-lg-4 mb-4">
              <FacebookShare :type="'box'" :href="url" />
            </div>
            <div class="col-md-6 col-lg-4 mb-4">
              <LineShare :type="'box'" :href="url" />
            </div>
            <div class="col-md-6 col-lg-4 mb-4">
              <PinterestShare :type="'box'" :href="url" />
            </div>
            <div class="col-md-6 col-lg-4 mb-4">
              <MailShare :type="'box'" :href="url" />
            </div>
            <div class="col-md-6 col-lg-4 mb-4">
              <UrlShare :type="'box'" :href="url" />
            </div>
          </div>
        </div>
        <div class="column full">
          <div class="spacer height-80"></div>
          <div class="text-center">
            <span>
              <button
                @click="prevBlog"
                class="iv2-blog-detail__btn theme__main-btn"
              >
                {{ $store.state.publicLang.news.list_button }}
              </button>
            </span>
          </div>
          <div class="spacer height-100"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FacebookShare from "@/components/items/BaseFacebookShare.vue";
import TwitterShare from "@/components/items/BaseTwitterShare.vue";
import LineShare from "@/components/items/BaseLineShare.vue";
import PinterestShare from "@/components/items/BasePinterestShare.vue";
import MailShare from "@/components/items/BaseMailShare.vue";
import UrlShare from "@/components/items/BaseUrlShare.vue";
import Favorite from "@/components/items/BaseFavorite.vue";

export default {
  components: {
    FacebookShare,
    TwitterShare,
    LineShare,
    PinterestShare,
    MailShare,
    UrlShare,
    Favorite,
  },

  props: {
    blog: Object,
    useShare: Number,
  },

  data() {
    return {
      url: "",
    };
  },

  created() {
    this.url = location.href;
  },

  mounted() {
    this.$nextTick(() => {
      window.customScripts();
    });
  },

  methods: {
    prevBlog() {
      this.$router.push({
        name: "blog",
        query: Object.assign({}, this.$route.query),
      });
    },

    updateDetail() {
      this.$emit("update");
    },
  },
};
</script>

<style lang="scss">
.iv2-blog-detail__title {
  font-size: 2.5rem;
  margin: 30px 0 0;
}

.iv2-blog-detail__subtitle {
  display: flex;
  justify-content: flex-end;
  margin: 30px 0;

  .iv2-blog-detail__date {
    margin-right: 20px;
    color: #777777;
  }
}

.iv2-blog-detail__btn {
  max-width: 100%;
  padding: 10px 80px;
}

/* TABLET Only */
@media (min-width: 768px) and (max-width: 1199px) {
  .iv2-blog-detail__title {
    font-size: 1.5rem;
  }
}

/* SP Only */
@media (max-width: 767px) {
  .iv2-blog-detail__title {
    font-size: 1.3rem;
  }
}
</style>
